'use-strict';
import { navActive, navRemove, closeBtnNav } from './hamburger';
import { revealSection } from './revealSections';
import switchMode from './switchMode';
import stickyNav from './stickyNav';
import updateText from './textAnimation';
import changeLogoColor from './logoColor';
import vanta from './vanta';

// Variables
const hamburgerBtn = document.querySelector('.hamburger');
const navClose = document.querySelector('.nav-close');
const switchBtn = document.querySelector('#switchMode');
const allSections = document.querySelectorAll('.section');

// Hamburger Menu
hamburgerBtn.addEventListener('click', navActive);
navClose.addEventListener('click', navRemove);
closeBtnNav();

// Light/Dark Mode
switchBtn.addEventListener('click', switchMode);
switchBtn.addEventListener('click', () => {
  const bgVideo = document.querySelector('.bg-video');
  bgVideo.classList.toggle('bg-video-hidden');
});

// Sticky Nav
window.addEventListener('scroll', stickyNav);

// Reveal sections
const sectionObserver = new IntersectionObserver(revealSection, {
  root: null,
  threshold: 0.15,
});

allSections.forEach(section => {
  sectionObserver.observe(section);
  section.classList.add('section-hidden');
});

// Color Logo
changeLogoColor();

// Animated text
setTimeout(() => updateText(), 1000);

// Vanta
vanta();
