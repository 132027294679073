'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const color1 = '#d82af1';
const color2 = '#56567e';

const vanta = VANTA.GLOBE({
  el: '#vanta-canvas',
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.0,
  minWidth: 200.0,
  scale: 1.0,
  scaleMobile: 1.0,
  color: color1,
  color2: color2,
  backgroundAlpha: 0,
});

export default vanta;
