'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const textUpdateSpeed = 55;
const blinkAnimDuration = 2400;

const textArr = [
  'to lead the way in the fintech industry...',
  'to build mass adoption technology for new generations...',
  'because we are cool coders...',
  'redefining finance for the new economy...',
];

let currentTextIndex = -1;

const myText = document.querySelector('.animated-text');
const typeCursor = document.querySelector('.cursor');

const addLetter = letterIndex => {
  if (letterIndex >= textArr[currentTextIndex].length) {
    blinkTypeCursor();
    return;
  }
  setTimeout(() => {
    myText.textContent += textArr[currentTextIndex][letterIndex];
    addLetter(letterIndex + 1);
  }, textUpdateSpeed);
};

const removeLetter = letterIndex => {
  if (letterIndex < 0) {
    updateText();
    return;
  }
  setTimeout(() => {
    myText.textContent = textArr[currentTextIndex].slice(0, letterIndex);
    removeLetter(letterIndex - 1);
  }, textUpdateSpeed);
};

const blinkTypeCursor = () => {
  typeCursor.classList.add('blinkAnim');
  setTimeout(() => {
    typeCursor.classList.remove('blinkAnim');
    removeLetter(textArr[currentTextIndex].length);
  }, blinkAnimDuration);
};

const updateText = () => {
  currentTextIndex++;
  if (currentTextIndex === textArr.length) {
    currentTextIndex = 0;
  }
  addLetter(0);
};

export default updateText;
