'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import sunIcon from 'url:../img/sun.svg';
import moonIcon from 'url:../img/moon.svg';

const switchBtn = document.querySelector('#switchMode');
const root = document.querySelector(':root');

const switchMode = () => {
  root.classList.toggle('light');

  switchBtn.innerHTML = `<img class="toggle-icon" src="${sunIcon}" alt="Sun">`;

  if (root.classList.contains('light')) {
    switchBtn.innerHTML = `<img class="toggle-icon" src="${moonIcon}" alt="Moon">`;
  } else {
    switchBtn.innerHTML = `<img class="toggle-icon" src="${sunIcon}" alt="Sun">`;
  }
};

export default switchMode;
