'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const navbar = document.querySelector('.navbar');
const jumbotron = document.querySelector('.jumbotron');

const initialCoords = jumbotron.getBoundingClientRect();

const stickyNav = () => {
  if (window.scrollY > initialCoords.top) {
    navbar.classList.add('navbar__sticky');
  } else {
    navbar.classList.remove('navbar__sticky');
  }
};

export default stickyNav;
