'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const nav = document.querySelector('.nav');
const navItem = document.querySelectorAll('.nav__item');

const closeBtnNav = () => {
  navItem.forEach(el => {
    el.addEventListener('click', () => {
      nav.classList.remove('nav-active');
    });
  });
};

const navActive = () => {
  nav.classList.toggle('nav-active');
};

const navRemove = () => {
  nav.classList.remove('nav-active');
};

export { navActive, navRemove, closeBtnNav };
