'use-strict';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const LogoInlineDark = document.querySelectorAll('.logo-inline-dark');
const LogoInlineLight = document.querySelectorAll('.logo-inline-light');
const btn = document.querySelector('#switchMode');

const changeLogoColor = () => {
  btn.addEventListener('click', () => {
    LogoInlineDark.forEach(el => {
      el.classList.toggle('logo-visible');
    });
    LogoInlineLight.forEach(el => {
      el.classList.toggle('logo-hidden');
    });
  });
};

export default changeLogoColor;
